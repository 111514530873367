import React from 'react';

function Spacer(props) {
  const { vertical, horizontal } = props;
  const componentStyle = {};

  if (vertical) {
    componentStyle.height = `${props.vertical}px`;
    return <div {...{ style: componentStyle }} />;
  }
  if (horizontal) {
    componentStyle.marginLeft = `${props.horizontal}px`;
    return <span {...{ style: componentStyle }} />;
  }
}

export default Spacer;
