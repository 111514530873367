// core
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import colors from '../../util/colors';
import { numberWithCommas } from '../../util/functions';
import Config from '../../util/Config';

// stytle
import iosIcon from '../../assets/img/apple-app-store-icon.png';

const styles = {
  timeline_container: {
    backgroundColor: '#fff',
    padding: '15px 25px'
  },
  status_label: {
    padding: '15px 25px',
    fontFamily: 'Lato',
    color: '#fff',
    fontSize: 15,
    maxWidth: 300,
    width: '33.334%',
    height: 50,
    fontStyle: 'normal'
  },
  road_show: {
    backgroundColor: colors.ROAD_SHOW
  },
  time_label: {
    fontSize: 15,
    fontFamily: 'Lato',
    fontWeight: 400
  }
};
function RoadShow(props) {
  const { revenue } = props;
  return (
    <div>
      <div className="stats">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ ...styles.status_label, ...styles.road_show }}>Road Show</div>
          <span style={{ ...styles.time_label, marginLeft: 25 }}>
            Auction Starts {moment(props.auction_start_date_time, Config.DATE_FORMAT).format('LLL')}
          </span>

          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}>
            <span style={{ fontSize: 15, fontWeight: 300 }}>
              {moment(props.auction_start_date_time, Config.DATE_FORMAT).fromNow()}
            </span>
          </div>
        </div>

        <div className="card-data-box">
          <div className="row">
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Target Offering</div>
                <h2 className="card-title">${numberWithCommas(revenue)}</h2>
              </div>
            </div>
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Interest Rate</div>
                <h3 className="card-title">{props.yield}%</h3>
              </div>
            </div>
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Term</div>
                <h3 className="card-title">{props.payments} months</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="smbx-content">
          {moment(props.auction_start_date_time, Config.DATE_FORMAT).diff(moment(), 'hours') >
          24 ? (
            <h4>Your bond offering will start soon.</h4>
          ) : (
            <h4>Your bond offering will start soon.</h4>
          )}
          <br />
          <div className="row">
            <div className="col-6" style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: 15, fontWeight: '400' }}>
                  {' '}
                  To view your bond offering download the SMBX app{' '}
                </p>
                <img src={iosIcon} width="120px" alt="Download the SMBX app" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { offering } = state;
  return {
    raise: offering.raise,
    yield: offering.yield,
    percent_subscribed: offering.percent_subscribed,
    revenue: offering.maximumAmountBeingRaised,
    total_bids: offering.total_bids,
    payments: offering.payments,
    auction_start_date_time: offering.auction_start_date_time
  };
}

export default connect(mapStateToProps)(RoadShow);
