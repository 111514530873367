/* eslint-disable camelcase */
// core
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';
import { fulfillPlacedBids } from '../../reducers/offering/actions';
import colors from '../../util/colors';
import { wholeNumberWithCommas } from '../../util/functions';
import Config from '../../util/Config';

// components
import Progressbar from '../../components/Progressbar';
import ClosablePanel from '../../components/ClosablePanel';
import BEPagination from '../../components/BEpagination';

// styles
const styles = {
  timeline_container: {
    backgroundColor: '#fff',
    padding: '15px 25px'
  },
  status_label: {
    padding: '15px 25px',
    fontFamily: 'Lato',
    color: '#fff',
    fontSize: 15,
    maxWidth: 300,
    width: '33.334%',
    height: 50,
    fontStyle: 'normal'
  },
  auction: {
    backgroundColor: colors.AUCTION
  },
  prebid: {
    backgroundColor: colors.AZURE
  },
  time_label: {
    fontSize: 15,
    fontFamily: 'Lato',
    fontWeight: 400
  }
};

function mapStateToProps(state) {
  const { offering } = state;
  return {
    raise: offering.raise,
    yield: offering.yield,
    percent_subscribed: offering.percent_subscribed,
    maximumAmountBeingRaised: offering.maximumAmountBeingRaised,
    placedBids: offering.placedBids,
    total_bids: offering.total_bids || 0,
    total_sold: offering.total_sold || 0,
    auction_end_date_time: offering.auction_end_date_time,
    payments: offering.payments
  };
}

function Auction(props) {
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    props.dispatch(fulfillPlacedBids(currentPage));
  }, [currentPage]);

  const renderBid = (item, index) => {
    return (
      <tr key={`Placed_bids_${index}`}>
        <td>
          <p>{moment(item.created_at, Config.DATE_FORMAT).fromNow()}</p>
        </td>
        <td>{item.user_fullname}</td>
        <td className="text-right">{wholeNumberWithCommas(item.number)}</td>
        <td className="text-right">
          ${wholeNumberWithCommas(item.number * item.principal_amount)}
        </td>
      </tr>
    );
  };

  const onChangePage = (items, pageChanged) => {
    if (pageChanged !== currentPage) {
      setCurrentPage(pageChanged);
    }
  };

  const { percent_subscribed, maximumAmountBeingRaised, placedBids, total_sold, status_label } =
    props;

  const statusColor =
    props.status === 1
      ? { ...styles.status_label, ...styles.prebid }
      : { ...styles.status_label, ...styles.auction };
  return (
    <div>
      <div className="stats">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={statusColor}>{status_label}</div>
          <span style={{ ...styles.time_label, marginLeft: 25 }}>
            Offering Closes on{' '}
            {moment(props.auction_end_date_time, Config.DATE_FORMAT).format('LLL')} Pacific
          </span>
        </div>
        <div className="card-data-box">
          <div className="row">
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Target Offering</div>
                <h2 className="card-title">${wholeNumberWithCommas(maximumAmountBeingRaised)}</h2>
              </div>
            </div>

            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Interest Rate</div>
                <h3 className="card-title">{props.yield} %</h3>
              </div>
            </div>

            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Term</div>
                <h3 className="card-title">{props.payments} months</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="card-data-box">
          <div className="row">
            <div className="col">
              <div className="card text-white card-data-offering" style={{ borderRightWidth: 0 }}>
                <div className="card-header">Amount Raised</div>
                <h3 className="card-title">${wholeNumberWithCommas(total_sold * 10)}</h3>
              </div>
            </div>

            <div className="col">
              <div
                className="card text-white card-data-offering"
                style={{ borderRightWidth: 0, paddingTop: 25 }}>
                <Progressbar limit={maximumAmountBeingRaised / 10} reached={total_sold} />
              </div>
            </div>

            <div className="col">
              <div
                className="card text-white card-data-offering"
                style={{ borderRightWidth: 0, paddingTop: 50 }}>
                <p style={{ color: '#000' }}>
                  {wholeNumberWithCommas(percent_subscribed)}% subscribed{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {placedBids.place_bids && placedBids.place_bids.length === 1 && (
        <ClosablePanel title="Congratulations! You have received your first bid." />
      )}
      <div className="smbx-content">
        <div className="row">
          <div className="col">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Investor</th>
                  <th className="text-right">Bonds</th>
                  <th className="text-right">Amount</th>
                </tr>
              </thead>
              {placedBids.placed_bids ? (
                <tbody>{placedBids.placed_bids.map(renderBid)}</tbody>
              ) : null}
            </table>
            {placedBids.placed_bids ? (
              <BEPagination
                currentPage={Number(currentPage + 1)}
                totalRecords={placedBids.elements}
                seePagination
                pageSize={100}
                items={placedBids.placed_bids}
                onChangePage={onChangePage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Auction);
