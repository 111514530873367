import React from 'react';

// styles
import useStyles from './styles/UI/tickerStyle';

function Ticker(props) {
  const { ticker } = props;
  const classes = useStyles();

  return <div className={`${classes.root} ${classes.primary} ${classes.ft16}`}>{ticker}</div>;
}

export default Ticker;
