// colors pallette
// eslint-disable prettier/prettier
// eslint-disable-next-line import/prefer-default-export
export const colors = {
  primary100: '#FAD92D',
  primary070: '#FBE46C',
  primary050: '#FDEC96',
  primary030: '#FFF8B3',

  secondary100: '#4BBEFF',
  secondary070: '#735CDD',
  secondary050: '#A5DFFF',
  secondary030: '#D5CEF5',

  grey100: '#3B3F46',
  grey070: '#82868E',
  grey050: '#C2C7CF',
  grey030: '#E5E7ED',

  black100: '#191B1E',
  black070: '#3B3F46',
  black050: '#82868E',
  black030: '#C2C7CF',

  white100: '#FFFFFF',

  success100: '#15B81C',
  success070: '#5BCD60',
  success050: '#8ADB8D',
  success030: '#B9EABB',

  error100: '#FF0033',
  error070: '#FF4D70',
  error050: '#FF8099',
  error030: '#FFB3C2',

  warning100: '#FCAD33',
  warning070: '#FDBE5D',
  warning050: '#FDD08B',
  warning030: '#FEE3BA',

  info100: '#0094FF',
  info070: '#4DB4FF',
  info050: '#80C9FF',
  info030: '#B3DFFF'
};
