// core
import React, { useState } from 'react';

// styles
const styles = {
  close: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '-10px'
  },
  close_icon: {
    fontSize: 30
  }
};

function ClosablePanel(props) {
  const [visible, setVisible] = useState(true);

  return visible ? (
    <div className="smbx-content">
      <div style={styles.close}>
        <i
          role="presentation"
          style={styles.close_icon}
          className="material-icons"
          onClick={() => {
            setVisible(false);
          }}>
          close
        </i>
      </div>

      <h4>{props.title}</h4>

      <div className="row">{props.children}</div>
    </div>
  ) : null;
}

export default ClosablePanel;
