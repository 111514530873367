// core
import React from 'react';
import { connect } from 'react-redux';

// components
import { numberWithCommas } from '../../util/functions';

// styles
const styles = {
  timeline_container: {
    backgroundColor: '#fff',
    padding: '15px 25px'
  },
  status_label: {
    padding: '15px 25px',
    fontFamily: 'Lato',
    color: '#fff',
    fontSize: 15,
    maxWidth: 300,
    width: '100%',
    height: 50,
    fontStyle: 'normal'
  },
  pre_launch: {
    backgroundColor: '#dedede'
  }
};
function PreLaunch(props) {
  const { revenue } = props;
  return (
    <div>
      <div className="stats">
        <div style={{ ...styles.status_label, ...styles.pre_launch }}>Pre Launch</div>
        <div className="card-data-box">
          <div className="row">
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Raising</div>
                <h2 className="card-title">${numberWithCommas(revenue)}</h2>
              </div>
            </div>

            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Interest Rate</div>
                <h2 className="card-title">{props.yield}%</h2>
              </div>
            </div>

            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Term</div>
                <h2 className="card-title">{props.payments} months</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { offering } = state;
  return {
    raise: offering.raise,
    revenue: offering.maximumAmountBeingRaised,
    yield: offering.yield,
    payments: offering.payments
  };
}

export default connect(mapStateToProps)(PreLaunch);
