import { createNotification, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify';
import Api from '../../util/Api';
import { showLoading, hideLoading, showAsyncLoading, hideAsyncLoading } from '../ux/actions';
import * as types from './actionTypes';

export const fulfillDocuments = (companySno) => {
  return async (dispatch, getState) => {
    showLoadingSelector(getState().documents, dispatch);
    const response = await Api.getInstance().getDocuments(companySno);
    hideLoadingSelector(true, dispatch);
    if (response.success) {
      dispatch({ type: types.FULFILL_DOCUMENTS, payload: response.data });
    } else {
      // TODO: ALERT FAILD
    }
  };
};

export const getForms = () => {
  return async (dispatch, getState) => {
    showLoadingSelector(getState().forms, dispatch);
    const response = await Api.getInstance().getForms();
    hideLoadingSelector(true, dispatch);
    if (response.success) {
      dispatch({ type: types.GET_FORMS, payload: response.data });
    } else {
      // TODO: ALERT FAILD
    }
  };
};

export const uploadDocument = (document, companySno) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().uploadDocument(document, companySno);
    dispatch(hideLoading());
    if (response.success) {
      dispatch({ type: types.FULFILL_DOCUMENTS, payload: response.data.documents });
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Document uploaded',
          duration: 1000
        })
      );
    }
  };
};

export const deleteDocument = (companySno, id) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().deleteDocument(companySno, id);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fulfillDocuments(companySno));
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Document deleted',
          duration: 1000
        })
      );
    }
  };
};

const showLoadingSelector = (data, dispatch) => {
  if (data) {
    dispatch(showAsyncLoading());
  } else {
    dispatch(showLoading());
  }
};

const hideLoadingSelector = (data, dispatch) => {
  dispatch(hideAsyncLoading());
  dispatch(hideLoading());
};
