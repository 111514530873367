import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
  seePagination: PropTypes.bool,
  totalRecords: PropTypes.number,
  currentPage: PropTypes.number
};

const defaultProps = {
  initialPage: 1,
  pageSize: 10,
  seePagination: false,
  totalRecords: 0,
  currentPage: 0
};

class BEPagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentDidMount() {
    // set page if items array isn't empty
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps) {
    // reset page if items array has changed
    if (
      this.props.items !== prevProps.items ||
      this.props.totalRecords !== prevProps.totalRecords
    ) {
      this.setPage(this.props.currentPage);
    }
  }

  getPager(totalItems, currentPage, pageSize, seePagination, totalRecords) {
    // default to first page
    const actualPage = currentPage || 1;

    // default page size is 10
    const actualPageSize = pageSize || 100;

    // calculate total pages
    const totalPages = Math.ceil(totalRecords / actualPageSize);

    let startPage;
    let endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else if (totalPages > 10) {
      // more than 10 total pages so calculate start and end pages
      if (actualPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (actualPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = actualPage - 5;
        endPage = actualPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (actualPage - 1) * actualPageSize;
    const endIndex = Math.min(startIndex + actualPageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems,
      actualPage,
      actualPageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
      seePagination,
      totalRecords
    };
  }

  setPage(page) {
    const { items, pageSize, seePagination, totalRecords } = this.props;
    let { pager } = this.state;
    const currentPage = page;
    if (currentPage < 1 || currentPage > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(items.length, currentPage, pageSize, seePagination, totalRecords);

    // get new page of items from items array
    const pageOfItems = items;

    // update state
    this.setState({ pager });

    // call change page function in parent component
    this.props.onChangePage(pageOfItems, currentPage - 1);
  }

  render() {
    const { pager } = this.state;

    if (!pager.totalRecords) {
      return <span className="no-orders">No orders to display</span>;
    }

    return (
      <div className="page-container">
        <span className="per-page"> Items per page: {pager.actualPageSize} </span>
        <span className="per-page">
          {' '}
          {pager.actualPage * pager.actualPageSize - (pager.actualPageSize - 1)} -
          {pager.actualPage * pager.actualPageSize > pager.totalRecords ? (
            <span>{pager.totalRecords} </span>
          ) : (
            <span>{pager.actualPage * pager.actualPageSize} </span>
          )}
          of {pager.totalRecords}{' '}
        </span>
        <ul className="pagination">
          <div
            role="presentation"
            onClick={() => this.setPage(1)}
            className={pager.actualPage === 1 ? 'disabled page-link' : 'page-link'}>
            <span className="material-icons">first_page</span>
          </div>
          <div
            role="presentation"
            onClick={() => this.setPage(pager.actualPage - 1)}
            className={pager.actualPage === 1 ? 'disabled page-link' : 'page-link'}>
            <span className="material-icons">keyboard_arrow_left</span>
          </div>
          <div
            role="presentation"
            onClick={() => this.setPage(pager.actualPage + 1)}
            className={pager.actualPage === pager.totalPages ? 'disabled page-link' : 'page-link'}>
            <span className="material-icons">keyboard_arrow_right</span>
          </div>
          <div
            role="presentation"
            onClick={() => this.setPage(pager.totalPages)}
            className={pager.actualPage === pager.totalPages ? 'disabled page-link' : 'page-link'}>
            <span className="material-icons">last_page</span>
          </div>
        </ul>
      </div>
    );
  }
}

BEPagination.propTypes = propTypes;
BEPagination.defaultProps = defaultProps;
export default BEPagination;
