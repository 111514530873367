import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import { postReply } from '../reducers/comments/actions';
import Config from '../util/Config';
import colors from '../util/colors';

// components
import Reply from './Reply';
import ReplyForm from './ReplyForm';

const styles = {
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 'inherit',
    cursor: 'pointer'
  }
};

export default function Comment(props) {
  const { item, onEditComment, onDeleteComment, onEditReply, onDeleteReply } = props;
  const dispatch = useDispatch();
  const handleEditComment = () => {
    onEditComment(item);
  };

  const handleDeleteComment = () => {
    onDeleteComment(item.comment_id);
  };

  const handlePostReply = (commentValue) => {
    dispatch(postReply(commentValue, item.comment_id));
  };

  return (
    <div style={{ backgroundColor: '#fff', marginTop: 25 }}>
      <div className="post">
        <div className="row">
          <div className="col-11 p-4">
            <h6>
              <img
                src={`${item.profile_image}`}
                width="30"
                height="30"
                alt="User Profile"
                onError={(event) => {
                  const handle = event;
                  // eslint-disable-next-line global-require
                  handle.target.src = require('../assets/img/f8e71c.png');
                }}
              />
              &nbsp;
              {item.username}
              <br />
              <br />
            </h6>
            <p>
              {parse(item.comment)}
              <br />
              <br />
            </p>
            <br />
            <br />
            <div className="float-left">
              <p>
                {moment(item.commented_at, Config.DATE_FORMAT).format(Config.OUTPUT_DATE_FORMAT)}
              </p>
            </div>
            {item.isOwner && (
              <div style={styles.toolbar} className="float-right">
                <div
                  onClick={handleEditComment}
                  style={styles.action}
                  className="action-info"
                  role="presentation">
                  <i className="material-icons md-dark md-inactive vertical-align-middle">edit</i>
                  <label style={{ marginBottom: 0 }}>Edit</label>
                </div>

                <div
                  onClick={handleDeleteComment}
                  style={{ ...styles.action, marginLeft: 10 }}
                  className="action-delete"
                  role="presentation">
                  <i className="material-icons md-dark md-inactive vertical-align-middle">delete</i>
                  <label style={{ marginBottom: 0 }}>Delete</label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {item.sub_comments.length > 0 &&
        item.sub_comments.map((reply) => (
          <Reply
            key={reply.comment_id}
            item={reply}
            parentId={item.comment_id}
            onEditReply={onEditReply}
            onDeleteReply={onDeleteReply}
          />
        ))}
      <div
        className="smbx-content createReply"
        style={{ backgroundColor: colors.BACKGROUND_GRAY, marginTop: 0 }}>
        <div className="row">
          <div
            className="smbx-content createReply"
            style={{ backgroundColor: colors.BACKGROUND_GRAY, marginTop: 0 }}
          />
          <ReplyForm editValue="" onSubmitForm={handlePostReply} buttonName="Reply" />
        </div>
      </div>
    </div>
  );
}
