import * as types from './actionTypes';

const initialState = { documents: [], forms: [] };

export default function documents(state = initialState, action = {}) {
  switch (action.type) {
    case types.FULFILL_DOCUMENTS:
      return { ...state, documents: action.payload };
    case types.GET_FORMS:
      return { ...state, forms: action.payload };
    default:
      return state;
  }
}
