import * as types from './actionTypes';

const initialState = [];
export default function investors(state = initialState, action = {}) {
  switch (action.type) {
    case types.FULFILL_INVESTORS:
      return [...action.payload];

    default:
      return state;
  }
}
