import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR
} from 'react-redux-notify';
import Api from '../../util/Api';
import { showLoading, hideLoading, showAsyncLoading, hideAsyncLoading } from '../ux/actions';
import * as types from './actionTypes';

export const deleteComment = (commentID) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().deleteComment(commentID);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Comment Deleted',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  };
};

export const deleteReply = (replyID, commentID) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().deleteReply(replyID, commentID);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Comment Deleted',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  };
};

export const fullfillComments = () => {
  return async (dispatch, getState) => {
    showLoadingSelector(getState().comments, dispatch);
    const response = await Api.getInstance().getComments();
    hideLoadingSelector(getState().comments, dispatch);
    if (response.success) {
      dispatch({ type: types.FULFILL_COMMENTS, payload: response.data });
    } else {
      // TODO: Handle Error
    }
  };
};

export const postComment = (comment) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().postComment(comment);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Comment Posted',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  };
};

export const postReply = (text, commentId) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().postSubcomment(text, commentId);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Reply posted',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  };
};

export const updateComment = (commentID, comment) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().updateComment(commentID, comment);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch({ type: types.UPDATE_COMMENT, payload: response.data });
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Comment Updated',
          duration: 1000
        })
      );
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  };
};

export const updateReply = (commentId, replyId, text) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().updateReply({ commentId, replyId, text });
    dispatch(hideLoading());
    if (response.success) {
      dispatch(fullfillComments());
      dispatch({ type: types.UPDATE_COMMENT, payload: response.data });
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Reply Updated',
          duration: 1000
        })
      );
    } else {
      dispatch(createNotification({ type: NOTIFICATION_TYPE_ERROR, message: response.message }));
    }
  };
};

const showLoadingSelector = (data, dispatch) => {
  if (data) {
    dispatch(showAsyncLoading());
  } else {
    dispatch(showLoading());
  }
};

const hideLoadingSelector = (data, dispatch) => {
  if (data) {
    dispatch(hideAsyncLoading());
  } else {
    dispatch(hideLoading());
  }
};
