import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Sidenav from '../components/Sidenav';
import Topbar from '../components/Topbar';

import BondOffering from './bondOffering/index';
import Comments from './Comments';
import Documents from './Documents';
import Settings from './Settings';

import { fulfillOverview } from '../reducers/offering/actions';

function Home(props) {
  useEffect(() => {
    props.dispatch(fulfillOverview());
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidenav />
        <div className="col no-gutters dash" style={{ minHeight: '100vh' }}>
          <Topbar />
          <div className="container-fluid dash-content-box">
            <div className="container-fluid dash-content">
              <Route path="/app" exact render={() => <BondOffering {...props} />} />
              <Route path="/app/comments" exact render={() => <Comments {...props} />} />
              <Route path="/app/documents/" exact render={() => <Documents {...props} />} />
              <Route path="/app/settings/" exact render={() => <Settings {...props} />} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect()(Home);
