// core
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import notifyReducer from 'react-redux-notify';
import thunk from 'redux-thunk';
import * as moment from 'moment-timezone';
import * as reducers from './reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Config from './util/Config';

// styles
import 'react-redux-notify/dist/ReactReduxNotify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/bootstrap.scss';

const reducer = combineReducers({
  ...reducers,
  notifications: notifyReducer
});
const store = createStore(reducer, applyMiddleware(thunk));

moment.tz.setDefault('America/Los_Angeles');

fetch(`${window.location.protocol}//${window.location.host}/apiendpoint.json`)
  .then((response) => response.json())
  .then((data) => {
    Config.SERVER = data.serverAPIURL;
    Config.API_SERVER = `${data.serverAPIURL}/api/`;
    Config.LOGROCKET_SERVICE = data.logrocket_service;
    const root = ReactDOMClient.createRoot(document.getElementById('root'));
    root.render(
      <Provider store={store}>
        <App />
      </Provider>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
