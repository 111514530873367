import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fulfillDocuments, getForms } from '../reducers/documents/actions';
import pdfImg from '../assets/img/pdf.svg';
import downloadIconImg from '../assets/img/iconDownload.svg';

class Documents extends Component {
  static defaultProps = {
    documents: [],
    forms: []
  };

  componentDidMount() {
    this.props.dispatch(fulfillDocuments(localStorage.getItem('company_sno')));
    this.props.dispatch(getForms());
  }

  openFile = (path) => {
    window.open(path, '_blank');
  };

  renderDocument = (item, index) => (
    <div className="row" key={`document_${index}`}>
      <div className="col">
        <img src={pdfImg} alt=".pdf document" />
        <span className="p">{item.name}</span>
      </div>
      <div className="col-2 text-right">
        <span
          onClick={this.openFile.bind(this, item.file)}
          onKeyDown={this.openFile.bind(this, item.file)}
          className="download-icon"
          role="presentation">
          <img src={downloadIconImg} alt="download" />
        </span>
      </div>
    </div>
  );

  renderForm = (item, index) => (
    <div className="row" key={`document_${index}`}>
      <div className="col">
        <img src={pdfImg} alt=".pdf 1099 form" />
        <span className="p">{item.fileName}</span>
        <span className="p">{item.fileSize}</span>
      </div>
      <div className="col-2 text-right">
        <span
          onClick={this.openFile.bind(this, item.fileUrl)}
          onKeyDown={this.openFile.bind(this, item.fileUrl)}
          className="download-icon"
          role="presentation">
          <img src={downloadIconImg} alt="download" />
        </span>
      </div>
    </div>
  );

  render() {
    return (
      <div className="documents-box">
        <div className="container">
          <div className="company-cover" />
        </div>
        <div className="smbx-content documents container">
          <div className="document-list">
            <div className="row">
              <div className="col">
                <h4 className="documents-title"> Documents </h4>
              </div>
            </div>
            <div className="file-row">
              {this.props.documents.length > 0 ? (
                this.props.documents.map(this.renderDocument)
              ) : (
                <span>There are no documents to view.</span>
              )}
            </div>
          </div>
        </div>
        <div className="smbx-content documents container">
          <div className="document-list">
            <div className="row">
              <div className="col">
                <h4 className="documents-title"> 1099 Forms </h4>
              </div>
            </div>
            <div className="file-row">
              {this.props.forms.length > 0 ? (
                this.props.forms.map(this.renderForm)
              ) : (
                <span>There are no 1099 forms to view.</span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    documents: state.documents.documents,
    forms: state.documents.forms
  };
}
export default connect(mapStateToProps)(Documents);
