export const routes = {
  auction: {
    // v1.1
    update: 'v1.1/auction',
    updateStatus: 'v1.1/auction/update/status'
  },
  bid: {
    list: 'v1.0/bid/list'
  },
  changePassword: 'v1.0/changePassword',
  company: {
    list: 'v1.0/company/list',
    create: 'v1.0/company/create',
    update: 'v1.0/company/update',
    overview: 'v1.0/company/overview',
    // team
    team: 'v1.0/company/team',
    addTeam: 'v1.0/company/add-team',
    // investor
    addInvestor: 'v1.0/company/add-investor',
    deleteInvestor: 'v1.0/company/delete-investor',
    // documents
    addDocument: 'v1.0/company/add-document',
    document: 'v1.0/company/document',
    deleteDocument: 'v1.0/company/delete-document',
    forms: 'v1.1/users/investor/list/form1099'
  },
  forgotPassword: 'v1.0/forgotPassword',
  /* industry:
    {
        list: "v1.0/industry/list",
    }, */

  location: {
    list: 'v1.0/location/list'
  },
  login: 'v1.0/login',
  offering: {
    about: 'v1.0/offering/about',
    comments: 'v1.0/offering/comments',
    overview: 'v1.0/offering/overview'
  },
  portfolio: {
    paymentsHistory: 'v1.0/portfolio/payments/history',
    paymentsScheduled: 'v1.0/portfolio/payments/scheduled',
    offeringInvestors: 'v1.0/portfolio/offering/investors'
  },
  security: {
    update: 'v1.0/security/update'
  },
  // states: "v1.0/states",
  user: {
    userImage: 'v1.0/user/userimage',
    checktoken: 'v1.0/checktoken',
    getUSer: '/v1.1/user/getuser'
  },
  amortizationTables: { scheduled: 'v1.1/amortizationtables/offering' }
};

export default routes;
