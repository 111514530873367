import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { Button } from '@material-ui/core';

// styles
import 'react-quill/dist/quill.snow.css';
import useStyles from '../web-component-library/smbx-commons/styles/UI/buttonStyle';

const styles = {
  editor: {
    height: '200px',
    marginBottom: '50px'
  },
  buttonHandler: {
    display: 'flex',
    width: '100%'
  }
};

function ReplyForm(props) {
  const { editValue, onSubmitForm, buttonName } = props;
  const [commentValue, setCommentValue] = useState(editValue || '');

  const handlePostReply = (e) => {
    e.preventDefault();
    onSubmitForm(commentValue);
    setCommentValue('');
  };

  const classes = useStyles();

  const disabled = commentValue === '' || commentValue === '<p><br></p>';
  return (
    <form>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <ReactQuill
                value={commentValue}
                onChange={setCommentValue}
                modules={{
                  toolbar: [
                    'bold',
                    'italic',
                    'underline',
                    'link',
                    { list: 'ordered' },
                    { list: 'bullet' }
                  ]
                }}
                style={styles.editor}
              />
            </div>
          </div>

          <div style={styles.buttonHandler}>
            <Button
              onClick={handlePostReply}
              className={`${classes.root} ${classes.primary} ${classes.ft16}`}
              disabled={disabled}>
              {buttonName}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ReplyForm;
