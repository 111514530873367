import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR,
  createNotification
} from 'react-redux-notify';

// components
import { Button } from '@material-ui/core';
import { Box, Divider } from '@mui/material';
import Api from '../../util/Api';
import Spacer from '../../components/UI/Spacer';
import { showLoading, hideLoading } from '../../reducers/ux/actions';

// styles
import stylesMain from '../../styles/signIn.module.scss';
import useStyles from '../../web-component-library/smbx-commons/styles/UI/buttonStyle';

function RecoverForm(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const emailRef = useRef('');
  const [formReady, setFormReady] = useState(false);
  const classes = useStyles();

  const isFormReady = () => {
    if (emailRef.current.value !== '') {
      setFormReady(true);
    } else {
      setFormReady(false);
    }
  };

  const recoverPassword = async (event) => {
    event.preventDefault();
    dispatch(showLoading());
    const response = await Api.getInstance().forgotPassword(emailRef.current.value);
    dispatch(hideLoading());

    if (response && response.success) {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'Check your email and follow the instructions',
          duration: 3000
        })
      );
      history.push('/signin');
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: 'Error on reset password',
          duration: 2000
        })
      );
    }
  };

  return (
    <form onSubmit={recoverPassword}>
      <Link to="/signin?" className={stylesMain.main_link_secondary}>
        ◂ <u>Back</u>
      </Link>
      <Spacer vertical="20" />
      <Box className={stylesMain.main_form_title}>Forgot Password</Box>
      <Spacer vertical="20" />
      <Divider className={stylesMain.main_divider} />
      <Spacer vertical="20" />
      <label htmlFor="inputEmail">Email Address</label>
      <input
        onChange={isFormReady}
        type="email"
        id="inputEmail"
        className={`form-control ${stylesMain.main_form_input}`}
        placeholder=""
        name="email"
        ref={emailRef}
        required
      />
      <Spacer vertical="40" />
      <Button
        className={`${classes.root} ${classes.primary} ${classes.ft16}`}
        type="submit"
        disabled={!formReady}>
        Reset Password
      </Button>
    </form>
  );
}

export default RecoverForm;
