import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createNotification, NOTIFICATION_TYPE_ERROR } from 'react-redux-notify';

// components
import { Box } from '@mui/material';
import SigninForm from './SigninForm';
import RecoverForm from './RecoverForm';
import Spacer from '../../components/UI/Spacer';

// styles
import stylesMain from '../../styles/signIn.module.scss';
import stylesHeader from '../../styles/newHeader.module.scss';
import logo from '../../assets/img/smbx-logo.svg';

export default function Signin(props) {
  const dispatch = useDispatch();
  const { search } = props.location;
  const params = new URLSearchParams(search);
  const forgotPassword = params.get('recover');

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const expired = query.get('expired');
    if (expired) {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: 'Session Expired',
          duration: 2000
        })
      );
      props.history.push('/signin');
    }
  }, []);

  return (
    <Box className={stylesMain.main}>
      <Box className={stylesHeader.header_content}>
        <Box className={stylesHeader.header_logo_centered}>
          <img src={logo} alt="smbx-logo" />
        </Box>
      </Box>
      <Box className={stylesMain.main_content}>
        <Spacer vertical="55" />
        <Box className={stylesMain.main_bussiness}>Business Sign In</Box>
        <Spacer vertical="50" />
        <Box className={stylesMain.main_centered}>
          <Box className={stylesMain.main_form}>
            <Box className={stylesMain.main_form_borderTop} />
            {forgotPassword ? (
              <RecoverForm history={props.history} location={props.location} />
            ) : (
              <SigninForm history={props.history} location={props.location} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
