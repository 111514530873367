// core
import React from 'react';
import colors from '../util/colors';
import { wholeNumberWithCommas } from '../util/functions';

// styles
const styles = {
  container: {},
  outer: {
    backgroundColor: colors.LIGHT_GREY_1,
    height: 15,
    position: 'relative'
  },
  inner: {
    position: 'absolute',
    height: 15,
    left: 0,
    width: '80%',
    backgroundColor: colors.AUCTION,
    borderRight: '2px solid #fff'
  },
  text: {
    color: colors.MID_GREYl,
    marginBottom: 5
  },
  reached: {
    color: '#000'
  }
};

function Progressbar(props) {
  const { limit, reached } = props;
  return (
    <div style={styles.container}>
      <p style={styles.text}>
        <span style={styles.reached}>{wholeNumberWithCommas(reached)}</span> /{' '}
        {wholeNumberWithCommas(limit)} bonds
      </p>
      <div style={styles.outer}>
        {(reached / limit) * 100 > 100 ? (
          <div style={{ ...styles.inner, width: `100%` }}> </div>
        ) : (
          <div style={{ ...styles.inner, width: `${(reached / limit) * 100}%` }}> </div>
        )}
      </div>
    </div>
  );
}

export default Progressbar;
