import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR
} from 'react-redux-notify';
import Api from '../../util/Api';
import { showLoading, hideLoading, showAsyncLoading, hideAsyncLoading } from '../ux/actions';
import * as types from './actionTypes';

export const changeState = (action, offeringSno = localStorage.getItem('offering_sno')) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().updateAuctionStatus(offeringSno, action);
    dispatch(hideLoading());
    if (response.success) {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_SUCCESS,
          message: 'State Updated',
          duration: 1000
        })
      );
      dispatch(fulfillOverview(offeringSno));
    } else {
      dispatch(
        createNotification({
          type: NOTIFICATION_TYPE_ERROR,
          message: response.message,
          duration: 2000
        })
      );
    }
  };
};

export const fulfillOverview = (offeringSno = localStorage.getItem('offering_sno')) => {
  return async (dispatch, getState) => {
    showLoadingSelector(getState().offering.symbol, dispatch);
    const response = await Api.getInstance().getOfferingOverview(offeringSno);
    hideLoadingSelector(getState().offering.symbol, dispatch);
    if (response.success) {
      dispatch({ type: types.FULFILL_OVERVIEW, payload: response.data });
    } else {
      // ToDo: Handle error
    }
  };
};

export const fulfillPlacedBids = (page, offeringSno = localStorage.getItem('offering_sno')) => {
  return async (dispatch) => {
    dispatch(showLoading());
    const response = await Api.getInstance().getPlacedBids(offeringSno, page);
    dispatch(hideLoading());
    if (response.success) {
      dispatch({ type: types.FULFILL_PLACED_BIDS, payload: response.data });
    } else {
      // TODO: Handle Error
    }
  };
};

export const fulfillScheduledPayments = (offeringSno) => {
  return async (dispatch) => {
    dispatch(showAsyncLoading());
    const response = await Api.getInstance().getSchedulePayments(offeringSno);
    dispatch(hideAsyncLoading());
    if (response.success) {
      const projectedPayments = response.data.payments || [];
      dispatch({ type: types.FULFILL_SCHEDULED_PAYMENTS, payload: projectedPayments });
    } else {
      // TODO: Handle Error
    }
  };
};

export const fullfillPaymentHistory = (offeringSno) => {
  return async (dispatch) => {
    dispatch(showAsyncLoading());
    const response = await Api.getInstance().getHistoryPayments(offeringSno);
    dispatch(hideAsyncLoading());
    if (response.success) {
      dispatch({ type: types.FULFILL_PAYMENT_HISTORY, payload: response.data });
    } else {
      // TODO: Handle Error
    }
  };
};

export const fulfillInvestors = (offeringSno) => {
  return async (dispatch) => {
    dispatch(showAsyncLoading());
    const response = await Api.getInstance().getInvestors(offeringSno);
    dispatch(hideAsyncLoading());
    if (response.success) {
      dispatch({ type: types.FULFILL_INVESTORS, payload: response.data });
    } else {
      // TODO: Handle Error
    }
  };
};

const showLoadingSelector = (data, dispatch) => {
  if (data) {
    dispatch(showAsyncLoading());
  } else {
    dispatch(showLoading());
  }
};

const hideLoadingSelector = (data, dispatch) => {
  if (data) {
    dispatch(hideAsyncLoading());
  } else {
    dispatch(hideLoading());
  }
};
