// core
import React from 'react';
import { connect } from 'react-redux';
import { hideConfirm } from '../reducers/ux/actions';

// styles
import stylesButton from '../styles/components/buttons.module.scss';

const styles = {
  backdrop: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: 'rgba(0,0,0,0.3)'
  },

  container: {
    width: 500,
    height: 500,
    backgroundColor: '#fff',
    padding: 50,
    paddingTop: 30,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  close: {
    position: 'absolute',
    top: 30,
    right: 20,
    cursor: 'pointer'
  },
  close_icon: {
    fontSize: 30
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  text: {
    fontSize: 20
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  button_margin: {
    margin: '0',
    marginRight: 50
  },
  button: {
    margin: '0'
  }
};

function Confirm(props) {
  const { title, body, onConfirm, visible } = props;
  return visible ? (
    <div style={styles.backdrop}>
      <div style={styles.container}>
        <div style={styles.header}>
          <h4>{title}</h4>
          <div style={styles.close}>
            <button
              type="button"
              onClick={() => {
                props.dispatch(hideConfirm());
              }}>
              <i style={styles.close_icon} className="material-icons">
                close
              </i>
            </button>
          </div>
        </div>
        <div style={styles.body}>
          <p style={styles.text}>{body}</p>
        </div>

        <div style={styles.toolbar}>
          <button
            style={styles.button_margin}
            onClick={() => {
              props.dispatch(hideConfirm());
            }}
            className={stylesButton.btn__secondary}
            type="submit">
            Cancel
          </button>
          <button
            onClick={() => {
              onConfirm();
              props.dispatch(hideConfirm());
            }}
            className={stylesButton.btn__primary}
            type="submit">
            Continue
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  title: state.ux.confirm.title,
  body: state.ux.confirm.body,
  onConfirm: state.ux.confirm.onConfirm,
  visible: state.ux.confirm.visible
});

export default connect(mapStateToProps)(Confirm);
