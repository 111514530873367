// core
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { signout } from '../reducers/user/actions';
import Ticker from '../web-component-library/smbx-commons/Ticker';

// styles
import defaultAvatar from '../assets/img/default-avatar.png';

function Topbar(props) {
  const { companyName, symbol, asyncLoading } = props;

  const avatar = props.avatar ? (
    <img src={props.avatar} style={styles.avatar} alt="User Avatar" />
  ) : (
    <img src={defaultAvatar} style={styles.noAvatar} alt="User Avatar" />
  );
  return (
    <div className="container-fluid top-bar">
      <div className="row">
        <div className="col no-gutters col-excode">
          <span className="excode">
            {symbol.split('').map((value) => (
              <Ticker ticker={value} />
            ))}
          </span>
        </div>
        <div className="col no-gutters company-bond-name">
          <h1>{companyName}</h1>
        </div>
        <div className="col-4 no-gutters">
          <div className="row">
            <div className="col-4">
              {asyncLoading && (
                <ReactLoading
                  type="spin"
                  color="#000"
                  height={30}
                  width={30}
                  className="float-right"
                />
              )}
            </div>
            <div className="col-8">
              <div className="username float-right" style={styles.username}>
                <span className="h6">
                  {props.name}
                  {avatar}
                </span>
                <div className="username-dropdown">
                  <div className="shadow">
                    <Link to="/app/settings">Account Settings</Link>
                    <span
                      role="presentation"
                      className="signout"
                      onClick={() => {
                        props.dispatch(signout(props.history));
                      }}>
                      Sign Out
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginLeft: 10
  },
  noAvatar: {
    height: 30,
    marginLeft: 10,
    paddingBottom: 5
  },
  username: {
    cursor: 'pointer'
  }
};

function mapStateToProps(state) {
  return {
    companyName: state.offering.companyName,
    symbol: state.offering.symbol,
    asyncLoading: state.ux.asyncLoading,
    name: state.user.name,
    avatar: state.user.avatar
  };
}

export default withRouter(connect(mapStateToProps)(Topbar));
