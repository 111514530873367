// core
import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';

// styles
import smbxLogo from '../assets/img/smbx-logo.svg';
import smbxLogoSmall from '../assets/img/smbx-logo-small.svg';

function Sidenav(props) {
  const menus = [
    { label: 'Bond Offering', icon: 'local_atm', path: '/app' },
    // {label: 'Team', icon: 'people', path: '/app/team'},
    // {label: 'Investors', icon: 'account_balance', path: '/app/investors'},
    { label: 'Comments', icon: 'chat', path: '/app/comments' },
    { label: 'Documents', icon: 'library_books', path: '/app/documents' }
  ];

  const [width, setWidth] = useState(800);

  useEffect(() => {
    updateDimensions();
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', updateDimensions());
    };
  }, []);

  const toggleNav = () => {
    setWidth(width <= 1073 ? 1074 : 1073);
  };

  const renderMenuItem = (item, index) => (
    <li key={`menu_${index}`}>
      <Link to={item.path} className={item.path === props.location.pathname ? 'active' : ''}>
        <i className="material-icons">{item.icon}</i>
        <span>{item.label}</span>
      </Link>
    </li>
  );

  const updateDimensions = () => {
    if (window.innerWidth < 500) {
      setWidth(450);
    } else {
      const updateWidth = window.innerWidth - 100;
      setWidth(updateWidth);
    }
  };

  const navClass = width <= 1073 ? 'col no-gutters nav-col nav-closed' : 'col no-gutters nav-col';
  return (
    <nav className={navClass}>
      <div className="nav-left">
        <span
          role="presentation"
          onClick={toggleNav}
          style={{ cursor: 'pointer', paddingLeft: '10px' }}>
          <img className="logo" src={smbxLogo} alt="logo" />
        </span>
        <ul
          role="presentation"
          onClick={toggleNav}
          style={{ cursor: 'pointer', paddingLeft: '10px' }}>
          <img className="logo-small" src={smbxLogoSmall} alt="logo_small" />
        </ul>
        <ul>{menus.map(renderMenuItem)}</ul>
      </div>
    </nav>
  );
}
export default withRouter(Sidenav);
