// export const API_SERVER = `${process.env.REACT_APP_SERVER_API}/api/`;
// export const SERVER = process.env.REACT_APP_SERVER_API;

export default {
  API_SERVER: '',
  SERVER: '',
  DATE_FORMAT: 'DD-MM-YYYY HH:mm',
  OUTPUT_DATE_FORMAT: 'MMM-DD-YYYY',
  LOGROCKET_SERVICE: ''
};

// export const API_SERVER = 'http://jenkins.thesmbx.com:8084/api/';
// export const SERVER = 'http://jenkins.thesmbx.com:8084';
