/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import colors from '../../util/colors';
import ClosablePanel from '../../components/ClosablePanel';
import {
  fulfillScheduledPayments,
  fullfillPaymentHistory,
  fulfillInvestors
} from '../../reducers/offering/actions';
import { numberWithCommas } from '../../util/functions';
import Config from '../../util/Config';

// styles
// eslint-disable-next-line no-unused-vars
import stylesMain from '../../styles/BondOffering/bond.module.scss';

class Settlement extends Component {
  static defaultProps = {
    scheduledPayments: [],
    paymentHistory: [],
    investors: []
  };

  constructor(props) {
    super(props);
    this.state = {
      tabActive: 1,
      paymentMonthly: {
        total: 0,
        stored: false
      }
    };
  }

  componentDidMount() {
    const offeringSno = localStorage.getItem('offering_sno');
    this.props.dispatch(fulfillScheduledPayments(offeringSno));
    this.props.dispatch(fullfillPaymentHistory(offeringSno));
    this.props.dispatch(fulfillInvestors(offeringSno));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.scheduledPayments !== this.props.scheduledPayments &&
      this.props.scheduledPayments.length > 0
    ) {
      this.setState({
        paymentMonthly: {
          total: this.props.scheduledPayments[0].total_payment,
          stored: true
        }
      });
    }
  }

  setTab = (tabActive) => {
    this.setState({ tabActive });
  };

  getScheduledPayments = () => {
    const { scheduledPayments } = this.props;
    const { paymentMonthly } = this.state;
    if (scheduledPayments.length > 0 && paymentMonthly.stored === false) {
      this.setState({
        paymentMonthly: {
          total: scheduledPayments[0].total_payment,
          stored: true
        }
      });
    }
    return (
      <div className="table-page">
        {scheduledPayments.length === 0 && (
          <ClosablePanel title="No scheduled payments to display." />
        )}
        {scheduledPayments.length > 0 && (
          <div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Payment #</th>
                  <th>Payment Date</th>
                  <th className="text-right">Beginning Principal Balance</th>
                  <th className="text-right">Principal</th>
                  <th className="text-right">Interest</th>
                  <th className="text-right">Payment Amount</th>
                  <th className="text-right">Ending Principal Balance</th>
                </tr>
              </thead>

              <tbody>
                {scheduledPayments.map((payment, index) => (
                  <tr key={`scheduled_payment_${index}`}>
                    <td>{payment.payment_no}</td>

                    <td>
                      {moment(payment.payment_date, 'MM-DD-YYYY').format(Config.OUTPUT_DATE_FORMAT)}
                    </td>
                    <td className="text-right">${numberWithCommas(payment.beginning_balance)}</td>
                    <td className="text-right">${numberWithCommas(payment.principal)}</td>
                    <td className="text-right">${numberWithCommas(payment.interest)}</td>
                    <td className="text-right">${numberWithCommas(payment.total_payment)}</td>
                    <td className="text-right">${numberWithCommas(payment.ending_balance)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  getPaymentHistory = () => {
    const { payments } = this.props.paymentHistory;
    const today = moment().tz('UTC');

    const paymentsDone = payments.filter(
      (payment) => moment(payment.payment_date.replaceAll('-', '/')).tz('UTC') < today
    );
    return (
      <div>
        {paymentsDone.length === 0 && <ClosablePanel title="No payments made to date." />}
        {paymentsDone.length > 0 && ( // #endregion
          <div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Payment #</th>
                  <th>Payment Date</th>
                  <th className="text-right">Beginning Principal Balance</th>
                  <th className="text-right">Principal</th>
                  <th className="text-right">Interest</th>
                  <th className="text-right">Payment Amount</th>
                  <th className="text-right">Ending Principal Balance</th>
                </tr>
              </thead>

              <tbody>
                {paymentsDone.map((payment, index) => (
                  <tr key={`scheduled_payment_${index}`}>
                    <td>{payment.payment_no}</td>

                    <td>
                      {moment(payment.payment_date, 'MM-DD-YYYY').format(Config.OUTPUT_DATE_FORMAT)}
                    </td>
                    <td className="text-right">${numberWithCommas(payment.beginning_balance)}</td>
                    <td className="text-right">${numberWithCommas(payment.principal)}</td>
                    <td className="text-right">${numberWithCommas(payment.interest)}</td>
                    <td className="text-right">${numberWithCommas(payment.total_payment)}</td>
                    <td className="text-right">${numberWithCommas(payment.ending_balance)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  getInvestors = () => {
    const { investors } = this.props;
    return (
      <div>
        {investors.length === 0 && <ClosablePanel title="No investors to display." />}
        {investors.length > 0 && (
          <div className="table-page">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Investor</th>
                  <th>Location</th>
                  <th className="text-right">Bonds Held</th>
                  <th className="text-right">Principal Held</th>
                </tr>
              </thead>
              <tbody>
                {investors.map((item) => (
                  <tr key={`investor_${item.id}`}>
                    <td>
                      {item.userDetails.firstname} {item.userDetails.lastname}
                    </td>
                    <td>
                      <span className={stylesMain.capitalize}>
                        {item.userDetails.city.toLowerCase()}
                      </span>
                      , {item.userDetails?.shortState}
                    </td>
                    <td className="text-right">{item.bondnumber}</td>
                    <td className="text-right">
                      {' '}
                      $
                      {item.bondnumber
                        ? numberWithCommas(
                            (item.bondnumber * item.userDetails.security_par_value).toFixed(2)
                          )
                        : 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      amount_raised,
      muturity_term,
      bond_settlement_date,
      financing_fee,
      monthly_payment,
      bond_issued,
      bond_yield
    } = this.props;
    const { tabActive } = this.state;
    const { total } = this.state.paymentMonthly;

    const p = monthly_payment * bond_issued * muturity_term - amount_raised;
    return (
      <div>
        <div className="card-data-box">
          <div className="row">
            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Term</div>
                <h3 className="card-title">{muturity_term} months</h3>
              </div>
            </div>

            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Interest Rate</div>
                <h3 className="card-title">{bond_yield} %</h3>
              </div>
            </div>

            <div className="col">
              <div className="card text-white card-data-offering">
                <div className="card-header">Amount Raised</div>
                <h2 className="card-title">${numberWithCommas(amount_raised)}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="smbx-content" style={styles.settlement_details}>
          <div className="row">
            <div className="col" style={{ borderRight: `1px solid ${colors.LIGHT_GREY_2}` }}>
              <div className="row" style={styles.cell}>
                <div className="col">
                  <p style={styles.label}>Start Date</p>
                </div>
                <div className="col">
                  <h6 style={styles.text}>
                    {moment(bond_settlement_date, Config.DATE_FORMAT).format(
                      Config.OUTPUT_DATE_FORMAT
                    )}
                  </h6>
                </div>
              </div>

              <div className="row" style={styles.cell}>
                <div className="col">
                  <p style={styles.label}>Maturity Date</p>
                </div>
                <div className="col">
                  <h6 style={styles.text}>
                    {moment(bond_settlement_date, Config.DATE_FORMAT)
                      .add(muturity_term, 'M')
                      .format(Config.OUTPUT_DATE_FORMAT)}
                  </h6>
                </div>
              </div>
              <div className="row" style={styles.cell}>
                <div className="col">
                  <p style={styles.label}>Monthly Payment</p>
                </div>
                <div className="col">
                  <h6 style={styles.text}>${numberWithCommas(total)}</h6>
                </div>
              </div>
            </div>
            <div className="col" style={{ borderRight: `1px solid ${colors.LIGHT_GREY_2}` }}>
              <div className="row" style={styles.cell}>
                <div className="col-9">
                  <p style={styles.label}>Investor Interest Rate</p>
                </div>
                <div className="col">
                  <h6 style={styles.text}>{bond_yield}%</h6>
                </div>
              </div>

              <div className="row" style={styles.cell}>
                <div className="col">
                  <p style={styles.label}>Total Bond Repayment</p>
                </div>
              </div>

              <div className="row" style={styles.cell}>
                <div className="col-9">
                  <p style={styles.label}>SMBX Capital Raise Fee</p>
                </div>
                <div className="col-3">
                  <h6 style={styles.text}>{financing_fee || '0'} %</h6>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row" style={styles.cell}>
                <div className="col">
                  <h6 style={styles.text}>${numberWithCommas(p.toFixed(2))}</h6>
                </div>
              </div>
              <div className="row" style={styles.cell}>
                <div className="col">
                  <h6 style={{ ...styles.text, fontWeight: 'bold' }}>
                    ${numberWithCommas((p + amount_raised).toFixed(2))}
                  </h6>
                </div>
              </div>
              <div className="row" style={styles.cell}>
                <div className="col">
                  <h6 style={styles.text}>
                    ${numberWithCommas(((amount_raised / 100) * financing_fee).toFixed(2))}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={styles.tabs}>
          <div
            onClick={this.setTab.bind(this, 1)}
            onKeyDown={this.setTab.bind(this, 1)}
            role="presentation"
            style={tabActive === 1 ? styles.tab_active : styles.tab}>
            Payment Schedule
          </div>
          <div
            onClick={this.setTab.bind(this, 2)}
            onKeyDown={this.setTab.bind(this, 2)}
            role="presentation"
            style={tabActive === 2 ? styles.tab_active : styles.tab}>
            Payment History
          </div>
          <div
            onClick={this.setTab.bind(this, 3)}
            onKeyDown={this.setTab.bind(this, 3)}
            role="presentation"
            style={tabActive === 3 ? styles.tab_active : { ...styles.tab, border: 0 }}>
            Investors
          </div>
        </div>
        <div className="smbx-content" style={{ marginTop: 0 }}>
          {tabActive === 1 && this.getScheduledPayments()}
          {tabActive === 2 && this.getPaymentHistory()}
          {tabActive === 3 && this.getInvestors()}
        </div>
      </div>
    );
  }
}

const styles = {
  settlement_details: {
    backgroundColor: colors.BACKGROUND_GRAY,
    marginTop: 0,
    borderTop: '1px solid black',
    borderTopColor: colors.LIGHT_GREY_2,
    padding: 0
  },
  label: {
    color: colors.DARK_GREY_2,
    background: 'none',
    fontSize: 12,
    margin: 0
  },
  text: {
    fontSize: 15,
    fontWeight: 300,
    textAlign: 'right',
    marginBottom: 0
  },
  cell: {
    borderBottom: `1px solid ${colors.LIGHT_GREY_2}`,
    padding: '13px 25px 13px 25px'
  },
  tabs: {
    marginTop: 25,
    display: 'flex',
    flexDirection: 'row'
  },
  tab_active: {
    fontSize: 15,
    fontWeight: 300,
    color: '#000',
    padding: '14px 25px',
    borderRight: '1px solid #fff',
    cursor: 'pointer',
    backgroundColor: '#fff',
    userSelect: 'none'
  },
  tab: {
    fontSize: 15,
    fontWeight: 300,
    color: colors.MID_GREYl,
    padding: '14px 25px',
    borderRight: '1px solid #fff',
    cursor: 'pointer',
    backgroundColor: colors.LIGHT_GREY_1,
    userSelect: 'none'
  }
};

function mapStateToProps(state) {
  const { offering } = state;
  return {
    revenue: offering.revenue,
    scheduledPayments: offering.scheduledPayments,
    paymentHistory: offering.paymentHistory,
    investors: offering.investors,
    payments: offering.payments,
    monthly_payment: offering.monthly_payment,
    bond_issued: offering.bond_issued,
    amount_raised: offering.amount_raised,
    muturity_term: offering.muturity_term,
    bond_settlement_date: offering.bond_settlement_date,
    financing_fee: offering.financing_fee,
    bond_yield: offering.yield
  };
}
export default connect(mapStateToProps)(Settlement);
