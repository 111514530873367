import * as types from './actionTypes';

const initialState = [];

export default function comments(state = initialState, action = {}) {
  switch (action.type) {
    case types.FULFILL_COMMENTS:
      if (action.payload) {
        return [...action.payload];
      }
      return initialState;

    case types.CREATE_COMMENT:
      return state;

    case types.UPDATE_COMMENT:
      return state;

    case types.DELETE_COMMENT:
      return state;

    default:
      return state;
  }
}
