import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// components
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Box, Divider } from '@mui/material';
import * as userActions from '../../reducers/user/actions';
import Spacer from '../../components/UI/Spacer';

// styles
import stylesMain from '../../styles/signIn.module.scss';
import useStyles from '../../web-component-library/smbx-commons/styles/UI/buttonStyle';

function SigninForm(props) {
  const { location, history } = props;
  const dispatch = useDispatch();
  const username = useRef('');
  const password = useRef('');
  const [formReady, setFormReady] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const classes = useStyles();

  const isFormReady = () => {
    if (username.current.value !== '' && password.current.value !== '') {
      setFormReady(true);
    } else {
      setFormReady(false);
    }
  };

  const signin = async (event) => {
    event.preventDefault();
    dispatch(
      userActions.signin(username.current.value, password.current.value, history, location.state)
    );
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <form onSubmit={signin}>
      <Spacer vertical="20" />
      <Box className={stylesMain.main_form_title}>Sign In</Box>
      <Spacer vertical="20" />
      <Divider className={stylesMain.main_divider} />
      <Spacer vertical="20" />
      <label htmlFor="inputEmail">Username</label>
      <input
        onChange={isFormReady}
        type="username"
        id="inputEmail"
        className={`form-control ${stylesMain.main_form_input}`}
        placeholder=""
        name="username"
        ref={username}
        required
      />
      <Spacer vertical="20" />
      <label htmlFor="inputPassword">Password</label>
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <input
          onChange={isFormReady}
          type={passwordShown ? 'text' : 'password'}
          id="inputPassword"
          className={`form-control ${stylesMain.main_form_input}`}
          placeholder=""
          name="password"
          ref={password}
          required
        />
        <FontAwesomeIcon
          icon={!passwordShown ? faEye : faEyeSlash}
          className={stylesMain.main_form_passwordIcon}
          onClick={togglePassword}
        />
      </Box>
      <Link to="/signin?recover=true" className={stylesMain.main_link_primary}>
        Forgot password?
      </Link>
      <Spacer vertical="40" />
      <Button
        className={`${classes.root} ${classes.primary} ${classes.ft16}`}
        type="submit"
        disabled={!formReady}>
        Sign in
      </Button>
    </form>
  );
}

export default SigninForm;
