import * as types from './actionTypes';

const initialState = {
  loading: false,
  asyncLoading: false,
  confirm: {
    title: '',
    body: '',
    onConfirm: () => {},
    visible: false
  }
};

export default function ux(state = initialState, action = {}) {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false
      };
    case types.SHOW_CONFIRM:
      return {
        ...state,
        confirm: {
          title: action.payload.title,
          body: action.payload.body,
          onConfirm: action.payload.onConfirm,
          visible: true
        }
      };

    case types.HIDE_CONFIRM:
      return {
        ...state,
        confirm: {
          title: '',
          body: '',
          onConfirm: () => {},
          visible: false
        }
      };
    case types.SHOW_ASYNC_LOADING: {
      return {
        ...state,
        asyncLoading: true
      };
    }

    case types.HIDE_ASYNC_LOADING: {
      return {
        ...state,
        asyncLoading: false
      };
    }

    default:
      return state;
  }
}
