// core
import React from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';

// styles
import colors from '../util/colors';

const styles = {
  container: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)'
  }
};

function Loading(props) {
  return props.loading ? (
    <div style={styles.container}>
      <ReactLoading type="cubes" color={colors.YELLOW} height={100} width={100} />
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  loading: state.ux.loading
});

export default connect(mapStateToProps)(Loading);
