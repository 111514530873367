// core
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Notify } from 'react-redux-notify';
import { connect } from 'react-redux';
import LogRocket from 'logrocket';
import Api from './util/Api';
import Config from './util/Config';
import { setUser } from './reducers/user/actions';

// components
import Loading from './components/Loading';
import Confirm from './components/Confirm';

// pages
import Signin from './views/Signin/Signin';
import Home from './views/Home';

function AlreadySignedIn({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem('token')) {
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: '/app' }} />;
      }}
    />
  );
}

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

function AppRouter(props) {
  useEffect(() => {
    if (localStorage.getItem('token')) {
      Api.setInstance(localStorage.getItem('token'));
    }
    const name = localStorage.getItem('name');
    const avatar = localStorage.getItem('avatar');
    if (name) {
      props.dispatch(setUser({ name, avatar }));
    }
    if (Config.logRocket !== '') LogRocket.init(Config.LOGROCKET_SERVICE);
  }, []);

  return (
    <Router>
      <div>
        <Confirm />
        <Notify />
        <Loading />
        <Switch>
          <AlreadySignedIn path="/signin" component={Signin} />
          <Redirect exact from="/app/company" to="/app" />
          <Redirect exact from="/app/about" to="/app" />
          <PrivateRoute path="/app" component={Home} />
          <Redirect exact from="/" to="/app" />
          <Route render={() => <NoMatch {...props} />} />
        </Switch>
      </div>
    </Router>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem('token')) {
          return <Component {...props} />;
        }
        // Pass current private pathName as state for redirect after login
        return <Redirect to={{ pathname: '/signin', state: props.location.pathname }} />;
      }}
    />
  );
}

export default connect()(AppRouter);
