import axios from 'axios';
import Config from './Config';
import { routes } from './Api.config';

class Api {
  constructor(headers = {}) {
    this.base = axios.create({
      baseURL: Config.API_SERVER,
      headers,
      params: {
        platform: 'issuerPanel'
      }
    });
  }

  static getInstance() {
    if (!Api.instance) {
      Api.instance = new Api();
    }
    return Api.instance;
  }

  static setInstance(token) {
    Api.instance = new Api({
      Authorization: `bearer ${token}`
    });
  }

  addInvestor = async (companySno, investor) => {
    const body = new FormData();
    body.append('name', investor.name);
    body.append('amount', investor.amount);
    body.append('company_sno', companySno);
    body.append('action', 'add-investor');
    try {
      const response = await this.base.post(routes.company.addInvestor, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  addTeam = async (companySno, team) => {
    const body = new FormData();
    body.append('team_name', team.team_name);
    body.append('designation', team.designation);
    body.append('team_image', team.team_image, team.team_image.name);
    body.append('company_sno', companySno);
    try {
      const response = await this.base.post(routes.company.addTeam, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  checkToken = async () => {
    try {
      const response = await this.base.get(routes.user.checktoken);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  deleteComment = async (commentID) => {
    const body = new FormData();
    body.append('offering_sno', localStorage.getItem('offering_sno'));
    try {
      const response = await this.base.delete(`${routes.offering.comments}/${commentID}`, {
        data: body
      });
      return { success: true, data: response.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  deleteDocument = async (companySno, id) => {
    const body = new FormData();
    body.append('id', id);
    body.append('company_sno', companySno);
    try {
      const response = await this.base.post(routes.company.deleteDocument, body);
      return { success: true, data: response.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  deleteInvestor = async (companySno, id) => {
    const body = new FormData();
    body.append('id', id);
    body.append('company_sno', companySno);
    try {
      const response = await this.base.post(routes.company.deleteInvestor, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  deleteReply = async (replyID, commentID) => {
    const body = new FormData();
    body.append('offering_sno', localStorage.getItem('offering_sno'));
    try {
      const response = await this.base.delete(
        `${routes.offering.comments}/${commentID}/subComments/${replyID}`,
        { data: body }
      );
      return { success: true, data: response.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  forgotPassword = async (email) => {
    try {
      const response = await this.base.get(`${routes.forgotPassword}/${email}`);
      return { success: true, data: response.data };
    } catch (e) {
      this.handleError(e);
    }
    return { success: false, data: '' };
  };

  getUser = async () => {
    try {
      const response = await this.base.get(routes.user.getUSer);
      return { success: true, data: response.data };
    } catch (e) {
      this.handleError(e);
    }
    return { success: false, data: '' };
  };

  getComments = async () => {
    try {
      const response = await this.base.get(
        `${routes.offering.comments}?offering_sno=${localStorage.getItem('offering_sno')}`
      );
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getCompany = async (companySno) => {
    try {
      const response = await this.base.get(`${routes.company.overview}?company_sno=${companySno}`);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getDocuments = async (companySno) => {
    try {
      const response = await this.base.get(`${routes.company.document}?company_sno=${companySno}`);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getForms = async () => {
    try {
      const response = await this.base.get(routes.company.forms);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getStates = async () => {
    try {
      const response = await this.base.get(routes.states);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  /* getLocations = async () => {
        try{
            const response = await this.base.get(routes.location.list);
            return {success: true, data: response.data.data};
        }catch(e){
            return this.handleError(e);
        }
    }

    getIndustries = async () => {
        try{
            const response = await this.base.get(routes.industry.list);
            return {success: true, data: response.data.data};
        }catch(e){
            return this.handleError(e);
        }
    } */

  getOfferingOverview = async (OfferingSno) => {
    try {
      const response = await this.base.get(
        `${routes.offering.overview}?offering_sno=${OfferingSno}`
      );
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getPlacedBids = async (OfferingSno, page) => {
    try {
      const response = await this.base.get(
        `${routes.bid.list}?auction_sno=${OfferingSno}&batch=${page}`
      );
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getHistoryPayments = async (OfferingSno) => {
    try {
      const body = new FormData();
      body.append('auction_sno', OfferingSno);
      const response = await this.base.post(routes.portfolio.paymentsHistory, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getInvestors = async (OfferingSno) => {
    try {
      const body = new FormData();
      body.append('auction_sno', OfferingSno);
      const response = await this.base.post(routes.portfolio.offeringInvestors, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getSchedulePayments = async (OfferingSno) => {
    try {
      const response = await this.base.get(`${routes.amortizationTables.scheduled}/${OfferingSno}`);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getStory = async (OfferingSno) => {
    try {
      const response = await this.base.get(`${routes.offering.about}?offering_sno=${OfferingSno}`);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  getTeams = async (companySno) => {
    try {
      const response = await this.base.get(`${routes.company.team}?company_sno=${companySno}`);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  postComment = async (comment) => {
    const body = new FormData();
    body.append('comment', comment);
    body.append('offering_sno', localStorage.getItem('offering_sno'));
    try {
      const response = await this.base.post(routes.offering.comments, body);
      return { success: true, data: response.data.comments };
    } catch (e) {
      return this.handleError(e);
    }
  };

  postSubcomment = async (text, commentId) => {
    const body = new FormData();
    body.append('offering_sno', localStorage.getItem('offering_sno'));
    body.append('comment', text);
    try {
      const response = await this.base.post(
        `${routes.offering.comments}/${commentId}/subComments`,
        body
      );
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  signin = async (username, password) => {
    const body = new FormData();
    body.append('username', username);
    body.append('password', password);
    try {
      const response = await this.base.post(routes.login, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  updateAuction = async (auction, auctionSno) => {
    const body = new FormData();
    body.append('auction_sno', auctionSno);
    body.append('number_for_sale', auction.number_for_sale);
    body.append('road_show_start_date_time', auction.road_show_start_date_time);
    body.append('pre_bid_start_date_time', auction.pre_bid_start_date_time);
    body.append('auction_start_date_time', auction.auction_start_date_time);
    body.append('auction_end_date_time', auction.auction_end_date_time);
    try {
      const response = await this.base.put(routes.auction.update, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  updateCompany = async (companySno, company) => {
    const body = new FormData();
    body.append('company_sno', companySno);
    body.append('company_name', company.companyName);
    body.append('industry_name', company.industry);
    body.append('business_type', company.companyType);
    body.append('website', company.website);
    body.append('city', company.city);
    body.append('state', company.state);
    body.append('zip', company.zip);

    if (!company.banner.indexOf) {
      body.append('select_banner_image', company.banner, `banner_${companySno}.jpg`);
    }
    if (!company.ic_banner.indexOf) {
      body.append('select_ic_banner_image', company.ic_banner, `ic_banner_${companySno}.jpg`);
    }
    if (!company.ic_bond_banner.indexOf) {
      body.append(
        'select_ic_bonds_banner_image',
        company.ic_bond_banner,
        `-c_bond_banner_${companySno}.jpg`
      );
    }
    if (!company.bond_certificate_image.indexOf) {
      body.append(
        'select_bond_certificate_image',
        company.bond_certificate_image,
        `bond_certificate_${companySno}.jpg`
      );
    }
    if (!company.social_image.indexOf) {
      body.append('select_social_image', company.social_image, `social_image_${companySno}.jpg`);
    }

    try {
      const response = await this.base.put(routes.company.update, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  updateReply = async (reply) => {
    const body = new FormData();
    body.append('offering_sno', localStorage.getItem('offering_sno'));
    body.append('message', reply.text);
    try {
      const response = await this.base.put(
        `${routes.offering.comments}/${reply.commentId}/subComments/${reply.replyId}`,
        body
      );
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  updateComment = async (commentID, message) => {
    const body = new FormData();
    body.append('offering_sno', localStorage.getItem('offering_sno'));
    body.append(`message`, message);
    try {
      const response = await this.base.put(`${routes.offering.comments}/${commentID}`, body);

      return { success: true, data: response.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  updateSecurity = async (security, auctionSno) => {
    const body = new FormData();
    body.append('symbol', security.symbol);
    body.append('maturity_term', security.maturity_term);
    body.append('auction_sno', auctionSno);
    try {
      const response = await this.base.post(routes.security.update, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  updateStory = async (OfferingSno, about) => {
    const body = new FormData();
    body.append('offering_sno', OfferingSno);
    body.append('story', about.story);
    body.append('future_plan', about.future_plan);
    if (about.story_image.name) {
      body.append('story_image', about.story_image, about.story_image.name);
    }
    if (about.future_plan_image.name) {
      body.append('future_plan_image', about.future_plan_image, about.future_plan_image.name);
    }

    try {
      const response = await this.base.put(routes.offering.about, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  uploadDocument = async (document, companySno) => {
    const body = new FormData();
    body.append('company_sno', companySno);
    body.append('documentname', document.name);
    body.append('document_file', document.file, `${document.name}.pdf`);
    try {
      const response = await this.base.post(routes.company.addDocument, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  updateAuctionStatus = async (OfferingSno, action) => {
    const body = new FormData();
    body.append('action', action);
    body.append('auction_sno', OfferingSno);

    try {
      const response = await this.base.post(routes.auction.updateStatus, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  updatePassword = async (oldPassword, newPassword) => {
    const body = new FormData();
    body.append('oldpassword', oldPassword);
    body.append('newPassword', newPassword);
    try {
      const response = await this.base.post(routes.changePassword, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  updatePicture = async (userImage) => {
    const body = new FormData();
    body.append('user_image', userImage, userImage.name);
    try {
      const response = await this.base.post(routes.user.userImage, body);
      return { success: true, data: response.data.data };
    } catch (e) {
      return this.handleError(e);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  handleError = (error) => {
    const handle = error;
    handle.response = handle.response || {};
    handle.response.data = handle.response.data || { message: handle.message };
    const { message } = handle.response.data;
    if (typeof message === 'object') {
      return { success: false, message: message[0].msg };
    }
    return { success: false, message };
  };
}

export default Api;
