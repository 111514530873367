import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../colors';

// eslint-disable-next-line import/prefer-default-export
const useStyles = makeStyles({
  // common
  root: {
    fontFamily: 'Lato',
    fontWeight: '400'
  },
  // designs
  primary: {
    justifyContent: 'center',
    alignContent: 'center',
    display: 'inline-flex',
    width: '24px',
    height: '24px',
    backgroundColor: '#000',
    color: colors.white100,
    margin: '1px'
  },

  // font sizes
  ft18: {
    fontSize: '18px'
  },
  ft16: {
    fontSize: '16px'
  },
  ft14: {
    fontSize: '14px'
  }
});

export default useStyles;
