import React from 'react';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import Config from '../util/Config';
import colors from '../util/colors';

// styles
const styles = {
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 'inherit',
    cursor: 'pointer'
  },
  reply: {
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 50,
    paddingBottom: 50,
    borderBottom: `1px solid ${colors.LIGHT_GREY_1}`,
    backgroundColor: colors.BACKGROUND_GRAY
  },
  reply_content: {
    display: 'flex'
  },
  reply_image: {
    borderRadius: 15
  },
  reply_title: {
    fontFamily: 'Lato',
    fontWeight: 500,
    marginBottom: 20
  },
  edit_reply_form: {
    display: 'flex'
  },
  edit_reply_button: {
    backgroundColor: colors.YELLOW,
    border: `1px solid ${colors.YELLOW}`,
    width: 80
  }
};

export default function Reply(props) {
  const { item, parentId, onEditReply, onDeleteReply } = props;

  const handleEditReply = () => {
    onEditReply(item, parentId);
  };

  const handleDeleteReply = () => {
    onDeleteReply(item.comment_id, parentId);
  };

  return (
    <div key={`reply_${item.comment_id}`} style={styles.reply}>
      <div style={styles.reply_content}>
        <img
          style={styles.reply_image}
          className="userIcon"
          src={`${item.profile_image}`}
          width="30"
          height="30"
          alt="User Icon"
          onError={(event) => {
            const handle = event;
            // eslint-disable-next-line global-require
            handle.target.src = require('../assets/img/f8e71c.png');
          }}
        />
        <div style={{ marginLeft: 10 }}>
          <div style={styles.reply_title}>{item.username}</div>
          <p style={styles.reply_text}>{parse(item.comment)}</p>
          <p>{moment(item.commented_at, Config.DATE_FORMAT).format(Config.OUTPUT_DATE_FORMAT)}</p>

          {item.isOwner && (
            <div style={styles.toolbar} className="float-right">
              <div
                onClick={handleEditReply}
                onKeyDown={handleEditReply}
                style={styles.action}
                className="action-info"
                role="presentation">
                <i className="material-icons md-dark md-inactive vertical-align-middle">edit</i>
                <label style={{ marginBottom: 0 }}>Edit</label>
              </div>
              <div
                onClick={handleDeleteReply}
                onKeyDown={handleDeleteReply}
                style={{ ...styles.action, marginLeft: 10 }}
                className="action-delete"
                role="presentation">
                <i className="material-icons md-dark md-inactive vertical-align-middle">delete</i>
                <label style={{ marginBottom: 0 }}>Delete</label>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
