export default {
  YELLOW: '#F8E71C',
  DARK_GREY_1: '#4D4D4D',
  DARK_GREY_2: '#656565',
  MID_GREYl: '#9B9B9B',
  LIGHT_GREY_1: '#DEDEDE',
  LIGHT_GREY_2: '#EBEBEB',
  RED_1: '#EE372A',
  RED_2: '#ED5247',
  RED_3: '#ED7E77',
  BLUE_1: '#00B0F4',
  BLUE_2: '#49C4F5',
  BLUE_3: '7AD2F5',
  ROAD_SHOW: '#00A1BA',
  PRE_BID: '#00CCAA',
  AUCTION: '#12CC31',
  BACKGROUND_GRAY: '#F5F5F5',
  CLOSE: '#666666',
  AZURE: '#00b0f4'
};
