import * as types from './actionTypes';

export const showLoading = () => ({ type: types.SHOW_LOADING });
export const hideLoading = () => ({ type: types.HIDE_LOADING });

export const showConfirm = (title, body, onConfirm) => ({
  type: types.SHOW_CONFIRM,
  payload: { title, body, onConfirm }
});
export const hideConfirm = () => ({ type: types.HIDE_CONFIRM });

export const showAsyncLoading = () => ({ type: types.SHOW_ASYNC_LOADING });
export const hideAsyncLoading = () => ({ type: types.HIDE_ASYNC_LOADING });
