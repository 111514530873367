// core
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fullfillComments,
  postComment,
  deleteComment,
  updateComment,
  deleteReply,
  updateReply
} from '../reducers/comments/actions';
import { showConfirm } from '../reducers/ux/actions';

// components
import ClosablePanel from '../components/ClosablePanel';
import Comment from '../components/Comment';

// styles
import 'react-quill/dist/quill.snow.css';
import ReplyForm from '../components/ReplyForm';

const styles = {
  close: {
    float: 'right',
    cursor: 'pointer',
    userSelect: 'none'
  },
  editor: {
    height: '200px',
    marginBottom: '50px'
  }
};

function Comments() {
  const comments = useSelector((state) => state.comments);
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const [isEditing, setIsEditing] = useState({ value: false, main: false });
  const [editInfo, setEditInfo] = useState('');
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    dispatch(fullfillComments());
  }, []);

  const handleEditComment = (comment) => {
    setEditValue(comment.comment);
    setEditInfo(comment.comment_id);
    setIsEditing({ value: true, main: true });
    setCollapsed(false);
    window.scrollTo(0, 0);
  };

  const handleEditReply = (comment, parentId) => {
    setEditValue(comment.comment);
    setEditInfo({
      id: comment.comment_id,
      parentId
    });
    setIsEditing({ value: true, main: false });
    setCollapsed(false);
    window.scrollTo(0, 0);
  };

  const handleDeleteComment = (commentId) => {
    dispatch(
      showConfirm(
        'Delete Comment',
        'Are you sure you want to delete the comment ? You wont be able to revert this',
        () => {
          dispatch(deleteComment(commentId));
        }
      )
    );
  };

  const handleDeleteReply = (replyId, commentId) => {
    dispatch(
      showConfirm(
        'Delete reply',
        'Are you sure you want to delete the reply ? You wont be able to revert this',
        () => {
          dispatch(deleteReply(replyId, commentId));
        }
      )
    );
  };

  const handlePostComment = (newComment) => {
    dispatch(postComment(newComment));
    setCollapsed(true);
  };

  const handleUpdateComment = (value) => {
    dispatch(updateComment(editInfo, value));
    setCollapsed(true);
    setEditValue('');
  };

  const handleUpdateReply = (value) => {
    dispatch(updateReply(editInfo.parentId, editInfo.id, value));
    setCollapsed(true);
    setEditValue('');
  };
  return (
    <div>
      <ClosablePanel title="Investors can post comments and questions about the bond offering.">
        <p>
          Use this page to reply to any investor questions and to share any news or updates about
          your business or the bond offering.
        </p>
      </ClosablePanel>

      <div className="smbx-content createPost">
        <h6 className="collapsible h5">
          Post an update{' '}
          <i
            onClick={() => {
              setCollapsed((prevState) => !prevState);
              setIsEditing({ value: false, main: false });
            }}
            style={styles.close}
            className="material-icons"
            role="presentation">
            {collapsed ? 'add' : 'close'}
          </i>{' '}
        </h6>

        {!collapsed ? (
          <div className="comment">
            <ReplyForm
              editValue={editValue}
              onSubmitForm={
                // eslint-disable-next-line no-nested-ternary
                isEditing.value
                  ? isEditing.main
                    ? handleUpdateComment
                    : handleUpdateReply
                  : handlePostComment
              }
              buttonName="Post Update"
            />
          </div>
        ) : null}
      </div>

      {comments.map((comment) => (
        <Comment
          key={comment.comment_id}
          item={comment}
          onEditComment={handleEditComment}
          onDeleteComment={handleDeleteComment}
          onEditReply={handleEditReply}
          onDeleteReply={handleDeleteReply}
        />
      ))}
    </div>
  );
}

export default Comments;
